<script setup lang="ts">
defineProps<{
  formUsers: PatientUserForm[];
}>();

const { enableFormUser, isPending } = useEnableFormUser();
</script>

<template>
  <PrimeDataTable class="primetable" :value="formUsers">
    <PrimeColumn field="user" header="Pacjent">
      <template #body="{ data }: { data: PatientUserForm }">
        {{ data.user?.name }} {{ data.user?.last_name }}
      </template>
    </PrimeColumn>

    <PrimeColumn field="name" header="Nazwa" />

    <PrimeColumn field="created_at" header="Data wysłania formularza">
      <template #body="{ data }: { data: PatientUserForm }">
        {{
          data.created_at
            ? formatDateToDaysMonthsYearsHoursSeconds(new Date(data.created_at))
            : '-'
        }}
      </template>
    </PrimeColumn>

    <PrimeColumn field="status" header="Status">
      <template #body="{ data }: { data: PatientUserForm }">
        <PrimeTag
          class="whitespace-nowrap"
          :severity="
            data.is_disabled
              ? 'danger'
              : data.form_user_results?.length
                ? 'success'
                : 'warn'
          "
        >
          <template v-if="data.is_disabled">
            <i class="pi pi-exclamation-triangle text-sm"></i>
            Zablokowane wzięcie leku
          </template>

          <template v-else-if="data.form_user_results?.length">
            Wypełniony
          </template>

          <template v-else> Do wypełnienia </template>
        </PrimeTag>
      </template>
    </PrimeColumn>

    <PrimeColumn>
      <template #body="{ data }: { data: PatientUserForm }">
        <div class="flex items-center justify-end gap-2">
          <PrimeButton
            v-if="data.is_disabled"
            size="small"
            text
            :disabled="isPending"
            @click="enableFormUser(data.id)"
          >
            <i class="pi pi-lock" />
            Odblokuj
          </PrimeButton>

          <LazyDetailsFormUser
            v-if="data.form_user_results?.length"
            :form-user="data"
          />
        </div>
      </template>
    </PrimeColumn>
  </PrimeDataTable>
</template>
